import React from 'react'
import PropTypes from 'prop-types'

import { Link as GatsbyLink } from 'gatsby'

function Link({ children, to, ...other }) {
  const isExternalLink = to.indexOf('http') >= 0
  const internalUri = to[0] === '/' ? to : `/${to}`

  if (isExternalLink) {
    return (
      <a href={to} rel="noopener" {...other}>
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink to={internalUri} {...other}>
      {children}
    </GatsbyLink>
  )
}
Link.propTypes = {
  to: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
}

Link.defaultProps = {
  to: null,
}

export default Link
