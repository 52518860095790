/* eslint-disable react/prop-types */
import React from 'react'
import { Footer as HorizonFooter } from 'ascential-horizon'
import getThemeConfig from '../../providers/config/themeConfig'
import { trackLinkClick, trackSocialLinkClick } from '../../services/tracking'

function Footer(footer) {
  const {
    copyrightText,
    footer: { type },
  } = getThemeConfig()
  const columns = footer.columns || []

  const columnsMapping = columns.map((column) => {
    const { copy, heading, instanceName, navItems } = column
    const nav = navItems || []
    if (column.__typename === 'ContentfulNavigation') {
      const trackedNav = nav.map((item) => ({
        ...item,
        onClick: () =>
          trackLinkClick(
            (item && item.label) || '',
            (item && item.url) || '',
            'footer',
            'link.click'
          ),
      }))
      return {
        type: 'nav',
        component: {
          label: instanceName,
          onClick: () =>
            trackLinkClick(instanceName, '', 'footer', 'link.click'),
          navItems: trackedNav,
        },
      }
    }

    if (column.__typename === 'ContentfulNavItems') {
      const trackedNavItems = column.items.map((item) => ({
        ...item,
        label: item?.item?.label,
        url: item?.item?.url,
        navItems:
          item.subNav &&
          item.subNav.map((subNavItem) => {
            return {
              ...subNavItem,
              label: subNavItem.item.label,
              url: subNavItem.item.url,
              onClick: () =>
                trackLinkClick(
                  subNavItem?.item?.label || '',
                  subNavItem?.item?.url || '',
                  'footer',
                  'link.click'
                ),
            }
          }),
        onClick: () =>
          trackLinkClick(
            (item && item.label) || '',
            (item && item.url) || '',
            'footer',
            'link.click'
          ),
      }))
      return {
        type: 'nav',
        component: {
          label: instanceName,
          onClick: () =>
            trackLinkClick(instanceName, '', 'footer', 'link.click'),
          items: trackedNavItems,
        },
      }
    }

    if (column.__typename === 'ContentfulTextBlock') {
      return {
        type: 'html',
        component: {
          label: heading,
          html:
            copy && copy.childMarkdownRemark && copy.childMarkdownRemark.html,
        },
      }
    }
    return null
  })

  const footerSecondaryNavigation =
    (footer.utilityNavigation && footer.utilityNavigation.navItems) || []
  const secNav = footerSecondaryNavigation.map((item) => ({
    ...item,
    onClick: () =>
      trackLinkClick(
        (item && item.label) || '',
        (item && item.url) || '',
        'footer',
        'link.click'
      ),
  }))

  const hasSocial =
    footer &&
    (footer.socialLabel ||
      footer.facebookUrl ||
      footer.linkedInUrl ||
      footer.twitterUrl ||
      footer.youTubeUrl ||
      footer.instagramUrl)

  const social = hasSocial && {
    text: footer.socialLabel,
    media: [
      {
        name: 'FacebookSquare',
        url: footer.facebookUrl,
        color: '#4267B2',
        onClick: () =>
          trackSocialLinkClick(
            'Facebook',
            footer.facebookUrl,
            'social',
            'social.click'
          ),
      },
      {
        name: 'LinkedinSquare',
        url: footer.linkedInUrl,
        color: '#0077B5',
        onClick: () =>
          trackSocialLinkClick(
            'Linkedin',
            footer.linkedInUrl,
            'social',
            'social.click'
          ),
      },
      {
        name: 'TwitterSquare',
        url: footer.twitterUrl,
        color: '#1DA1F2',
        onClick: () =>
          trackSocialLinkClick(
            'Twitter',
            footer.twitterUrl,
            'social',
            'social.click'
          ),
      },
      {
        name: 'YoutubeSquare',
        url: footer.youTubeUrl,
        color: '#C4302B',
        onClick: () =>
          trackSocialLinkClick(
            'Youtube',
            footer.youTubeUrl,
            'social',
            'social.click'
          ),
      },
      {
        name: 'InstagramSquare',
        url: footer.instagramUrl,
        color: 'primary',
        onClick: () =>
          trackSocialLinkClick('Instagram', footer.instagramUrl, 'social'),
      },
    ],
  }

  const footerProps = columns && {
    columns: columnsMapping,
    copyrightText,
    secondaryLinks: secNav,
    social,
    variation: type,
  }

  return footerProps ? <HorizonFooter {...footerProps} /> : null
}

export default Footer
