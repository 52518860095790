/* eslint-disable react/prop-types */
import React from 'react'
import { useTheme } from 'emotion-theming'
import {
  Banner as HorizonBanner,
  Container,
  Nav as HorizonNav,
  Announcement,
  Strip,
} from 'ascential-horizon'
import getThemeConfig from '../../providers/config/themeConfig'
import { trackBtnClick, trackLinkClick } from '../../services/tracking'
import { useHasMounted } from '../../utils/hooks'
import { useIdentity } from '../../providers/identityContext'
import { NavBanner, NavWrapper } from './index.style'

const Stickyfill = process.browser ? require('stickyfilljs') : null

function Nav({ pageType, slug, location, announcement, ...header }) {
  const hasMounted = useHasMounted()
  const { loggedIn, user } = useIdentity()

  if (hasMounted && typeof Stickyfill !== 'undefined') {
    const stickyHeader = document.querySelectorAll('.sticky')
    Stickyfill.add(stickyHeader)
  }
  const { banner: bannerTheme } = useTheme()
  const {
    header: { position },
    annoucementVariation,
  } = getThemeConfig()
  const isSticky = position === 'sticky'

  const generateNavMenuProps = (menuItems) => {
    if (!menuItems || menuItems.length === 0) {
      return []
    }

    return menuItems.map((menuItem) => {
      const { label, url } = menuItem.item || {}

      return {
        label,
        url,
        description: menuItem?.description,
        ...(menuItem?.subNav
          ? {
              navItems: generateNavMenuProps(menuItem.subNav),
            }
          : {}),
        onClick: () => trackLinkClick(label, url, 'topNav', 'link.click'),
      }
    })
  }
  const generateButtonProps = (buttons) => {
    if (!buttons || buttons.length === 0) {
      return []
    }

    return buttons.map((button) => ({
      ...button,
      onClick: () => trackBtnClick(button.label, button.url),
    }))
  }

  const primaryLinks = generateNavMenuProps(header.primaryNavigation)
  const secondaryLinks = generateNavMenuProps(header.utilityNavigation)
  const userMenu = generateNavMenuProps(header.userNavigation)
  const actions = generateButtonProps(header.ctas)

  const banner = header?.banner
  let accountProps = null

  if (loggedIn) {
    accountProps = {
      avatar: {
        name: user?.name || '',
        image: null,
      },
      menu: userMenu,
    }
  }

  const logoImage = header.logoImage ? { ...header.logoImage } : null

  if (logoImage && !logoImage.fluid) {
    logoImage.src = logoImage.file.url
  }

  const navProps = header && {
    account: accountProps,
    actions,
    activePath: `/${slug}`,
    homeLink: header?.homeLink ?? '/',
    logoImage,
    primaryLinks,
    secondaryLinks,
  }

  if (banner && banner.image && !banner.image.fluid) {
    banner.image.src = banner.image.file.url
  }

  const bannerProps = banner && {
    ...banner,
    position: 'flex-end',
    textSize: 'large',
    size: 'large',
    textColor: bannerTheme.headerTextColor,
    useProgressiveImage: false,
  }

  const announcementSubtitle =
    announcement &&
    announcement.description &&
    announcement.description.childMarkdownRemark &&
    announcement.description.childMarkdownRemark.html

  const announcementCta =
    (announcement &&
      announcement.ctaLabel &&
      announcement.ctaUrl && {
        label: announcement.ctaLabel,
        url: announcement.ctaUrl,
      }) ||
    null

  const announcementProps = announcement && {
    title: announcement.title || '',
    subtitle: announcementSubtitle || '',
    button: announcementCta,
    variant: announcement.variant,
  }

  const headerContent = (
    <>
      <HorizonNav {...navProps} />
      {banner && (
        <NavBanner isSticky={isSticky} secondaryLinks={secondaryLinks}>
          <Container>
            <HorizonBanner {...bannerProps} />
          </Container>
        </NavBanner>
      )}
    </>
  )

  const AnnouncementVariation =
    annoucementVariation?.type === 'strip' ? (
      <Strip
        text={announcementProps?.subtitle}
        variant={announcementProps?.variant}
      />
    ) : (
      <Announcement {...announcementProps} />
    )

  return navProps ? (
    <>
      {isSticky ? (
        <>
          {announcementProps && AnnouncementVariation}
          <NavWrapper className="sticky" isSticky={isSticky}>
            {headerContent}
          </NavWrapper>
        </>
      ) : (
        <NavWrapper>{headerContent}</NavWrapper>
      )}
    </>
  ) : null
}

export default Nav
