/* eslint-disable react/prop-types */
import React from 'react'
import {
  ascential,
  horizon,
  cannesLions,
  lionsLive,
  money2020,
  spikes,
  dcl,
  lionsCreativity,
  MainTheme as HorizonTheme,
} from 'ascential-horizon'
import Link from '../Link'

const themes = {
  ascential,
  horizon,
  cannesLions,
  lionsLive,
  money2020,
  spikes,
  dcl,
  lionsCreativity,
}

function MainTheme({ children }) {
  return (
    <HorizonTheme
      theme={themes[process.env.GATSBY_BUILD_THEME] || horizon}
      link={Link}>
      {children}
    </HorizonTheme>
  )
}

export default MainTheme
