import React from 'react'
import MainTheme from '../components/MainTheme'
import Header from '../components/Header'
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import SEO from '../components/SEO'
import getThemeConfig from '../providers/config/themeConfig'

function Layout(data) {
  const {
    announcement,
    header,
    footer,
    slug,
    pageType,
    children,
    pageContext,
    location,
  } = data
  const {
    header: { variation },
    meta,
  } = getThemeConfig()

  const { pageDescription, pageTitle, pageImageUrl, canonicalUrl } =
    pageContext || {}

  const seoProps = {
    title: pageTitle,
    description: pageDescription,
    image: pageImageUrl,
    url:
      canonicalUrl ||
      (location && location.href && location.href.replace(location.search, '')),
    fbDomainVerification: (meta && meta.fbDomainVerification) || null,
  }

  const HeaderComponent = variation === 'nav' ? Nav : Header

  return (
    <MainTheme>
      {header && (
        <HeaderComponent
          {...header}
          slug={slug}
          pageType={pageType}
          location={location}
          announcement={announcement}
        />
      )}
      {pageContext && <SEO {...seoProps} />}
      {children}
      <Footer {...footer} />
    </MainTheme>
  )
}

export default Layout
