import { useState, useLayoutEffect, useEffect, useMemo } from 'react'

export const useWindowWidth = () => {
  const [width, setWidth] = useState(0)

  useLayoutEffect(() => {
    const updateWidth = () =>
      setWidth(typeof window !== `undefined` ? window.innerWidth : 0)
    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window.removeEventListener('resize', updateWidth)
  }, [])
  return width
}

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  return hasMounted
}

export const usePaginationData = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1)

  const currentPageData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * itemsPerPage
    const lastPageIndex = firstPageIndex + itemsPerPage
    return data && Array.isArray(data)
      ? data.slice(firstPageIndex, lastPageIndex)
      : []
  }, [currentPage, data, itemsPerPage])

  return { currentPage, setCurrentPage, currentPageData }
}

export default useWindowWidth
