import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Facebook from './Facebook'
import Twitter from './Twitter'

const SEO = ({ title, description, image, url, fbDomainVerification }) => {
  return (
    <>
      <Helmet title={title}>
        <html lang="en-US" />
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="canonical" href={url} />
      </Helmet>
      <Facebook
        desc={description}
        image={image}
        title={title}
        url={url}
        fbDomainVerification={fbDomainVerification}
      />
      <Twitter title={title} image={image} desc={description} />
    </>
  )
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  fbDomainVerification: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  url: null,
  fbDomainVerification: null,
}

export default SEO
