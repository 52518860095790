import styled from '@emotion/styled'

export const NavBanner = styled('div')(
  ({ theme: { banner, colors, header, mediaQueries }, secondaryLinks }) => {
    return {
      width: '100%',
      padding: '0.8rem 0',
      background: colors[banner.headerBgColor],
      borderBottom:
        header.primary.border === 'none'
          ? header.border
          : `1px solid ${colors[header.primary.border]}`,

      [mediaQueries.medium]: {
        top: secondaryLinks.length > 0 ? '16.8rem' : '12rem',
      },
    }
  }
)

export const NavWrapper = styled('div')(({ isSticky }) => {
  const fixedPos = isSticky && {
    position: 'sticky',
    top: '-1px',
    zIndex: '1000',
  }

  return {
    ...fixedPos,
    width: '100%',
  }
})
